.loading-overlay-relative {
  position: relative; }

.loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #232535e0;
  text-align: center;
  z-index: 1000; }
  .loading-overlay__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .loading-overlay__spinner {
    transition: 0.5s ease-in-out background-color;
    background-color: #39b549; }
